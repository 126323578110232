import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { AbsBottom, ArticleTitle, ButtonOutline, Date, Description, HoverEffect, PaddingWrapper, PostWrapper, UpdateDate } from './Post.style'

export default function Post({ post, image }) {
    let excerpt = Boolean(post.blog.zajawka) ? post.blog.zajawka.slice(0, 140) : '';
    excerpt =  excerpt + '...';
    
  return (
    <PostWrapper className="slider-box">
      <HoverEffect to={`/blog/${post.slug}/`}>
        <div className="img-box">
          <div className="img">
              <GatsbyImage image={image} alt={post.title} title={post.title}/>
          </div>
        </div>
        <PaddingWrapper>
          <Date>
            {post.dateGmt}
            <UpdateDate>{post.modifiedGmt}</UpdateDate>
          </Date>
          <ArticleTitle>{post.title}</ArticleTitle>
          <Description>
              {excerpt}
            </Description>
          <AbsBottom>
            <ButtonOutline>Czytaj dalej</ButtonOutline>
          </AbsBottom>
        </PaddingWrapper>
      </HoverEffect>
    </PostWrapper>
  )
}
