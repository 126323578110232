import { getImage, StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import Hero from '../components/Hero/Hero'
import Layout from '../components/Layout/Layout'
import LastPosts from '../components/Posts/LastPosts'
import Post from '../components/Posts/Post'
import Seo from '../components/Seo/Seo'
import StickyBox from '../components/StickyBox/StickyBox'

export default function Author({ pageContext }) {
  const seo = pageContext.seo.seo
  const path = pageContext.seo.slug
  const posts = pageContext.posts.nodes
  const name = pageContext.seo.name
  const description = [pageContext.seo.description]

  return (
    <Layout>
      <Seo title={seo.title} description={seo.metaDesc} pathname={`/${path}/`}/>

      <Container>
        <Hero
          subheading="Autor"
          heading={name}
          paragraphs={description}
          img={
            <StaticImage
              src="../assets/images/about/about.png"
              loading="eager"
              width={650}
              quality={90}
              placeholder="none"
              alt="Ranking dobrych polis na życie"
              title="Ranking dobrych polis na życie"
            />
          }
        />

        <Row>
          <Col lg={8}>
            <Row>
              {posts.map((post, index) => {
                if(post.categories.nodes[0].slug === 'blog') {
                  const image = Boolean(post.featuredImage) ? getImage(post.featuredImage.node.localFile) : null;
                  return (
                    <Col md={6} key={index}>
                      <Post post={post} image={image} />
                    </Col>
                    
                  )
                }
              })}
            </Row>
          </Col>
          <Col lg={4}>
            <StickyBox />
          </Col>
        </Row>
        <Row>
          <Col>
            <LastPosts />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

