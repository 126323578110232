import { Link } from 'gatsby'
import styled from 'styled-components'

import CalendarImg from '../../assets/images/icons/calendar.svg'
import ArrowImg from '../../assets/images/icons/right-arrow.svg'
import UpdateImg from '../../assets/images/icons/update.svg'
import { color } from '../../assets/styles/variables'

export const PostWrapper = styled.div`
  background-color: #ffffff;
  width: ${props => props.width ? props.width : '100%'};
  border-radius: 7px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
  margin: 0px 0 25px 4px;
  position: relative;
  a:hover,
  a:focus {
    text-decoration: none;
  }

  .gatsby-image-wrapper {
    overflow: hidden;
    position: relative;
    height: 250px;
    width: 100%;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    img {
      position: absolute;
      width: 100%;
    }
  }
  @media screen and (max-width: 768px){
    width: 98%;
  }
`
export const HoverEffect = styled(Link)`
  &:hover,
  &:focus {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255,255,255,1);
    border-radius: 7px;
    opacity: 0.4;
    cursor: pointer;
  }
`

export const ButtonOutline = styled.span`
  appearance: none;
  color: ${color.primary};
  font-weight: 600;
  font-size: 18px;
  &:hover,
  &:focus {
    text-decoration: none;
    outline: 0;
  }
  &:after {
    content: '';
    mask: url(${ArrowImg}) no-repeat 50% 50%;
    mask-size: cover;
    width: 20px;
    height: 20px;
    background-color: ${color.primary};
    display: inline-block;
    position: relative;
    margin-left: 10px;
    top: 5px;
  }
`
export const PaddingWrapper = styled.div`
  padding: 24px 40px 60px 40px;
  min-height: 250px;
  @media screen and (max-width: 1399px){
    min-height: 280px;
  }
  @media screen and (max-width: 991px) {
    padding: 24px 24px 50px 24px !important;
  }
  @media screen and (max-width: 768px){
    min-height: 250px;
  }
`
export const ArticleTitle = styled.p`
  font-size: 18px;
  color: ${color.primary};
  font-weight: 600;
  margin-top: 10px;
  line-height: 1.4;
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
`
export const Description = styled.p`
  font-size: 14px;
  color: ${color.textDark};
  font-weight: 400;
  margin-top: 15px;
  min-height: 40px;
`
export const AbsBottom = styled.div`
  position: absolute;
  bottom: 25px;
  left: 40px;
  @media screen and (max-width: 991px) {
    left: 24px;
  }
`
export const Date = styled.div`
  color: ${color.primary};
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  &:before {
    content: '';
    mask: url(${CalendarImg}) no-repeat 50% 50%;
    mask-size: cover;
    width: 16px;
    height: 16px;
    background-color: ${color.secondary};
    display: inline-block;
    position: relative;
    margin-right: 10px;
    top: 2px;
  }
`
export const UpdateDate = styled.div`
  color: ${color.primary};
  padding-left: 10px;
  display: inline-block;
  &:before {
    content: '';
    mask: url(${UpdateImg}) no-repeat 50% 50%;
    mask-size: cover;
    width: 14px;
    height: 14px;
    background-color: ${color.secondary};
    display: inline-block;
    position: relative;
    margin-right: 10px;
    top: 2px;
  }
`
