import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'

import Slide from '../Slide/Slide'
import Post from './Post'

export default function LastPosts() {
  const data = useStaticQuery(query)
  const posts = data.allWpPost.edges
  return (
    <div className="posts-slider">
      <Slide heading="Strefa wiedzy">
        {posts.map((post, index) => {
          const image = getImage(post.node.featuredImage.node.localFile)
          return <Post post={post.node} image={image} key={index} />
        })}
      </Slide>
    </div>
  )
}

const query = graphql`
  query last10Posts {
    allWpPost(
      sort: { order: DESC, fields: dateGmt }
      filter: {
        categories: {
          nodes: { elemMatch: { slug: { ne: "towarzystwo-ubezpieczeniowe" } } }
        }
      }
      limit: 10
    ) {
      edges {
        node {
          dateGmt(formatString: "DD.MM.YYYY")
          id
          title
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 600)
                }
              }
            }
          }
          modifiedGmt(formatString: "DD.MM.YYYY")
          blog {
            zajawka
          }
        }
      }
    }
  }
`
